// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "D_tY d_gS d_cw d_dv";
export var quoteParagraphCenter = "D_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "D_gV d_gV d_cw d_dx";
export var quoteRowLeft = "D_tZ d_bG";
export var quoteRowCenter = "D_t0 d_bD";
export var quoteRowRight = "D_t1 d_bH";
export var quoteWrapper = "D_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "D_gR d_gR";
export var quoteExceptionSmall = "D_t2 K_t2";
export var quoteExceptionNormal = "D_t3 K_t3";
export var quoteExceptionLarge = "D_t4 K_t4";
export var quoteAuthorExceptionSmall = "D_t5 K_t5";
export var quoteAuthorExceptionNormal = "D_t6 K_t6";
export var quoteAuthorExceptionLarge = "D_t7 K_t7";