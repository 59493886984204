// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "M_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "M_fR d_fR d_bz d_bJ";
export var menuDesign6 = "M_wP d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "M_wQ d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "M_wR d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "M_wS d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "M_wT d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "M_m7 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "M_nj d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "M_wV";
export var navbarItem = "M_m8 d_bx";
export var navbarLogoItemWrapper = "M_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "M_wW d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "M_wX d_gd d_by d_Z d_bs";
export var burgerToggle = "M_wY d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "M_wZ d_gd d_by d_Z d_bs";
export var burgerInput = "M_w0 d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "M_w1 d_f3 d_w d_H";
export var burgerLine = "M_w2 d_f1";
export var burgerMenuDesign6 = "M_w3 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "M_w4 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "M_w5 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "M_w6 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "M_w7 d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "M_w8 d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "M_w9 d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "M_xb d_bC d_bP";
export var compact = "M_xc";
export var navDivided = "M_xd";
export var staticBurger = "M_xf";
export var menu = "M_xg";
export var navbarDividedLogo = "M_xh";
export var nav = "M_xj";