import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import { formColor } from '../../../../../helper';
import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import * as styles from './styles.module.css';

class FeaturesLayout extends React.Component {
  constructor(props) {
    super(props);

    const align = props.section.styles.align !== undefined ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';
    const layout = props.section.styles.layout ? props.section.styles.layout : 'left';

    let columns;
    if (props.section && props.section.styles && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      section: props.section,
      full,
      align,
      layout,
      columns,
    };
  }

  render() {
    let columns;
    if (this.state.columns === 1) columns = 'col-12';
    else
      columns = `col-12 col-md-6${
        this.state.columns ? ` col-lg-${12 / this.state.columns}` : this.props.layout === 'left' ? ' col-lg-3' : ''
      }`;

    const features = [];
    this.state.section.data.forEach((item, index) => {
      if (item.type === 'COMPONENT' && item.active) {
        let imgElem;
        let buttonElem;
        const elems = [];
        const texts = [];
        item.data.forEach((elem, i) => {
          const cards = this.state.layout === 'cards' ? 'Cards' : '';

          if (elem.active && elem.type === 'IMAGES/IMAGE') {
            let sizes = '(min-width: 992px) 25vw, (min-width: 768px) 50vw, 100vw';
            if (this.state.layout === 'cards') {
              sizes = '(min-width: 768px) 50vw, 100vw';
            }

            const test =
              this.props.layout === 'cards' &&
              !elem.content.round &&
              !elem.content.crop &&
              (elem.content.width == null || elem.content.width === 100);
            imgElem = (
              <div
                key={`imageItem_${this.props.section._id}_${index}${i}`}
                className={clsx(
                  styles.sectionMediaWrapper,
                  styles[`align${this.props.layout === 'cards' && this.props.matches ? 'Center' : item.align}`],
                  test && styles.cardsWrapper,
                )}
              >
                <CustomSectionMedia
                  data={elem.content}
                  images={this.props.images}
                  pagePathList={this.props.pagePathList}
                  articlePathList={this.props.articlePathList}
                  filePathList={this.props.filePathList}
                  categoryPathList={this.props.categoryPathList}
                  sectionSlugs={this.props.sectionSlugs}
                  colors={this.props.themeData.colors}
                  sizes={sizes}
                />
              </div>
            );

            if (this.state.layout === 'left') elems.push(imgElem);
          } else if (elem.active && elem.type.startsWith('BUTTONS/')) {
            buttonElem = (
              <div
                key={`${this.state.section._id}_layout_${index}${i}`}
                className={`${styles[`btnWrapper${cards}`]} ${styles[`align${item.align}`]}`}
              >
                <ButtonEditor
                  buttons={this.props.buttons}
                  themeData={this.props.themeData}
                  data={elem.content}
                  pagePathList={this.props.pagePathList}
                  articlePathList={this.props.articlePathList}
                  filePathList={this.props.filePathList}
                  categoryPathList={this.props.categoryPathList}
                  sectionSlugs={this.props.sectionSlugs}
                />
              </div>
            );

            if (this.state.layout === 'left') elems.push(buttonElem);
          } else if (elem.active && (elem.type.startsWith('PARAGRAPH/') || elem.type.startsWith('HEADINGS/'))) {
            let styleName;
            let text;

            if (elem.type.startsWith('PARAGRAPH/')) {
              styleName = `${styles[`align${elem.align || this.props.align}`]} ${styles.featuresComponentParagraph}`;
              text = `<div>${elem.text}</div>`;
            } else {
              styleName = `${styles[`align${elem.align || this.props.align}`]} ${styles.featuresComponentHeader}`;
              text = `<h3>${elem.text}</h3>`;
            }

            const value = (
              <div key={`textItem_${this.state.section._id}_${index}${i}`} className={styleName}>
                {HTMLParser(text)}
              </div>
            );

            texts.push(value);
            if (
              this.state.layout === 'left' &&
              (!item.data[i + 1].active ||
                (!item.data[i + 1].type.startsWith('PARAGRAPH/') && !item.data[i + 1].type.startsWith('HEADINGS/')))
            ) {
              elems.push(<div className={styles[`alignColumn${item.align}`]}>{texts}</div>);
            }
          }
        });

        let style;
        if (this.state.section.data[index].styles.backgroundColor.active) {
          let { backgroundColor } = this.state.section.data[index].styles;
          if (
            this.state.section.data[index].styles.backgroundColor.solid === '' &&
            this.state.section.data[index].styles.backgroundColor.gradient.from === ''
          ) {
            backgroundColor = { solid: '#ffffff' };
          }

          style = formColor(
            backgroundColor,
            false,
            this.state.section.data[index].styles.backgroundColor.opacity,
            undefined,
            this.props.themeData.colors,
          );
        }

        if (style !== undefined) {
          style.paddingTop = '15px';
          style.paddingBottom = '15px';
        }

        const result = (
          <div
            key={`${this.state.section._id}_layout_${index}`}
            className={`${columns} ${styles.featuresComponentWrapper} ${
              styles[`align${this.state.layout === 'left' ? 'Column' : ''}${item.align}`]
            }`}
          >
            <div style={style} className={styles.compContentWrapper}>
              {this.state.layout === 'left' ? (
                elems
              ) : (
                <div className={`row no-gutters ${styles.featuresComponentWrapperRow}`}>
                  <div className="col-12 col-md-5">{imgElem}</div>
                  <div className={`col-12 col-md-7 ${styles.featuresBlockWrapper}`}>
                    <div className={styles.featuresTextBlockWrapper}>
                      {texts}
                      {buttonElem}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );

        features.push(result);

        return null;
      }

      return null;
    });

    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`featuresContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          {this.state.section.data.map((elem, i) => {
            let result;
            if (elem.active && (elem.type.startsWith('HEADINGS/') || elem.type.startsWith('PARAGRAPH/'))) {
              let styleName;
              let content;

              if (elem.type === 'HEADINGS/HEADING-TWO') {
                styleName = styles.featuresMainHeader;
                content = `<h2>${elem.text}</h2>`;
              } else if (elem.active && elem.type === 'PARAGRAPH/PARAGRAPH') {
                styleName = styles.featuresSubHeader;
                content = `<span>${elem.text}</span>`;
              }

              result = (
                <div key={`${this.state.section._id}_data_${i}`} className={`col-12 ${styleName}`}>
                  {HTMLParser(content)}
                </div>
              );
            }

            return result;
          })}
          {features}
        </div>
      </div>
    );
  }
}

export default FeaturesLayout;
