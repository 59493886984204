// extracted by mini-css-extract-plugin
export var socialContainer = "F_t8";
export var instagramLink = "F_t9";
export var socialWrapperLeft = "F_qx d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "F_vb";
export var socialContentContainerFull = "F_vc";
export var instagramContainer = "F_vd";
export var twitterContainer = "F_vf";
export var facebookContainer = "F_vg";
export var socialErrorContainer = "F_vh";
export var facebookContainerWide = "F_vj";
export var twitterContainerWide = "F_vk";
export var socialParagraphCenter = "F_vl";
export var instaWrapper = "F_vm";
export var SubtitleSmall = "F_pb K_pb K_vQ K_v1";
export var SubtitleNormal = "F_pc K_pc K_vQ K_v2";
export var SubtitleLarge = "F_pd K_pd K_vQ K_v3";
export var textLeft = "F_dv";
export var textCenter = "F_dw";
export var textRight = "F_dx";