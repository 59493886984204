// extracted by mini-css-extract-plugin
export var articleWrapper = "s_ny";
export var searchResult = "s_rZ s_ny";
export var line = "s_fk d_fk d_cY";
export var articleTitle = "s_r0 d_cq";
export var articleText = "s_nz d_cs";
export var authorImage = "s_ln d_ln d_b7 d_Z d_ct";
export var authorText = "s_n0 d_ch d_ct";
export var textCenter = "s_dw d_dw";
export var searchWrapper = "s_r1 d_bz d_bP d_dr d_cl d_Z";
export var searchInput = "s_r2 d_cF d_w d_b4";
export var searchBtn = "s_r3 d_b1 d_bC d_bP d_0 d_cD d_dB";
export var text = "s_n9 d_cr";
export var elementWrapper = "s_mW d_cl";
export var select = "s_r4 d_w d_b4";
export var articleImageWrapper = "s_nR d_cs d_Z d_V";
export var articleImageWrapperIcon = "s_nS d_cs d_Z d_V";
export var articleImageWrapperColumns = "s_r5 d_cs d_fh d_Z";
export var contentPadding = "s_r6 d_cS";
export var otherPadding = "s_r7 d_cM";
export var paginationWrapper = "s_r8";
export var pagination = "s_r9";
export var disabledPagination = "s_sb";
export var breakMe = "s_sc";
export var activePage = "s_sd";
export var next = "s_sf";
export var pages = "s_sg";
export var searchTitle = "s_sh d_cr";
export var categoryTitle = "s_sj d_cr d_Z";
export var searchText = "s_sk d_ct";
export var bold = "s_sl";
export var field = "s_sm";
export var dateTag = "s_nJ d_l d_cr d_dq";
export var icon1 = "s_nK d_cP";
export var icon2 = "s_nL d_cP d_cJ";
export var tagBtn = "s_nM d_b1 d_cD d_dp d_dB";
export var linksWrapper = "s_n3 d_ch d_dw";
export var header = "s_nB d_w d_Z";
export var headerInner = "s_sn";
export var headerImage = "s_sp d_bd d_0 d_8 d_7 d_4 d_9";
export var separator = "s_nZ d_cY";
export var resultImage = "s_sq";