// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "G_vn d_bD";
export var storyRowWrapper = "G_hx d_hx d_bK";
export var storyLeftWrapper = "G_vp d_bz d_bP";
export var storyWrapperFull = "G_vq d_cD";
export var storyWrapperFullLeft = "G_qX d_cD d_bz d_bP d_bD";
export var contentWrapper = "G_mS d_hy";
export var storyLeftWrapperCenter = "G_vr d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "G_vs d_hF";
export var storyHeader = "G_vt d_hD d_w d_cs";
export var storyHeaderCenter = "G_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "G_hB d_hB d_by d_dw";
export var storyBtnWrapper = "G_vv d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "G_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "G_rg d_fg d_Z";
export var imageWrapperFull = "G_vw d_w d_H d_bf d_Z";
export var SubtitleSmall = "G_pb K_pb K_vQ K_v1";
export var SubtitleNormal = "G_pc K_pc K_vQ K_v2";
export var SubtitleLarge = "G_pd K_pd K_vQ K_v3";
export var textLeft = "G_dv";
export var textCenter = "G_dw";
export var textRight = "G_dx";