// extracted by mini-css-extract-plugin
export var alignLeft = "C_rb d_bG";
export var alignCenter = "C_bP d_bD";
export var alignRight = "C_rc d_bH";
export var textAlignLeft = "C_tP";
export var textAlignCenter = "C_tQ";
export var textAlignRight = "C_tR";
export var hoursInnerWrapperAlt = "C_tS d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "C_tT d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "C_tV d_dw";
export var titleMargin = "C_tW d_cw";
export var hoursInnerInnerWrapper = "C_tX d_cz";