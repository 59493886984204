// extracted by mini-css-extract-plugin
export var alignDiscLeft = "y_s1 d_fp d_bG d_dv";
export var alignLeft = "y_rb d_fp d_bG d_dv";
export var alignDiscCenter = "y_s2 d_fq d_bD d_dw";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignDiscRight = "y_s3 d_fr d_bH d_dx";
export var alignRight = "y_rc d_fr d_bH d_dx";
export var footerContainer = "y_s4 d_dW d_bW";
export var footerContainerFull = "y_s5 d_dT d_bW";
export var footerHeader = "y_kf d_kf";
export var footerTextWrapper = "y_s6 d_w";
export var footerDisclaimerWrapper = "y_km d_km d_ck";
export var badgeWrapper = "y_s7 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "y_s8 d_bz d_bJ d_bN d_bL";
export var wide = "y_s9 d_cy";
export var right = "y_tb d_bK";
export var line = "y_fk d_fl d_cv";
export var badgeDisclaimer = "y_tc d_bC d_bP d_bJ";
export var badgeContainer = "y_td d_bz d_bH d_bP";
export var badge = "y_tf";
export var padding = "y_tg d_c7";
export var end = "y_th d_bH";
export var linkWrapper = "y_tj d_dB";
export var link = "y_mZ d_dB";
export var colWrapper = "y_tk d_cx";
export var consent = "y_f d_f d_bC d_bP";
export var disclaimer = "y_tl d_bz d_bJ";
export var media = "y_tm d_bx d_dy";
export var itemRight = "y_tn";
export var itemLeft = "y_tp";
export var itemCenter = "y_tq";
export var exceptionWeight = "y_tr K_wg";