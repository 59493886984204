// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_ts d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_tt d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_tv d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_tw d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_tx d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_ty d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_tz d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_tB K_tB";
export var heroExceptionNormal = "z_tC K_tC";
export var heroExceptionLarge = "z_tD K_tD";
export var Title1Small = "z_tF K_tF K_vQ K_vR";
export var Title1Normal = "z_tG K_tG K_vQ K_vS";
export var Title1Large = "z_tH K_tH K_vQ K_vT";
export var BodySmall = "z_tJ K_tJ K_vQ K_v7";
export var BodyNormal = "z_tK K_tK K_vQ K_v8";
export var BodyLarge = "z_tL K_tL K_vQ K_v9";