// extracted by mini-css-extract-plugin
export var carouselContainer = "P_xl d_w d_H d_bf d_Z";
export var carouselContainerCards = "P_xm P_xl d_w d_H d_bf d_Z";
export var carouselContainerSides = "P_xn d_w d_H d_Z";
export var prevCarouselItem = "P_xp d_w d_H d_0 d_k";
export var prevCarouselItemL = "P_xq P_xp d_w d_H d_0 d_k";
export var moveInFromLeft = "P_xr";
export var prevCarouselItemR = "P_xs P_xp d_w d_H d_0 d_k";
export var moveInFromRight = "P_xt";
export var selectedCarouselItem = "P_xv d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "P_xw P_xv d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "P_xx P_xv d_w d_H d_Z d_bf";
export var nextCarouselItem = "P_xy d_w d_H d_0 d_k";
export var nextCarouselItemL = "P_xz P_xy d_w d_H d_0 d_k";
export var nextCarouselItemR = "P_xB P_xy d_w d_H d_0 d_k";
export var arrowContainer = "P_xC d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "P_xD P_xC d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "P_xF P_xD P_xC d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "P_xG P_xC d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "P_xH";
export var nextArrowContainerHidden = "P_xJ P_xG P_xC d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "P_kG d_0";
export var prevArrow = "P_xK P_kG d_0";
export var nextArrow = "P_xL P_kG d_0";
export var carouselIndicatorContainer = "P_xM d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "P_xN d_w d_bz d_bF";
export var carouselText = "P_xP d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "P_xQ P_xP d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "P_xR d_b7";
export var carouselIndicator = "P_xS P_xR d_b7";
export var carouselIndicatorSelected = "P_xT P_xR d_b7";
export var arrowsContainerTopRight = "P_xV d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "P_xW d_0 d_bl d_bC";
export var arrowsContainerSides = "P_xX d_0 d_bl d_bC";
export var smallArrowsBase = "P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "P_xZ P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "P_x0 P_xZ P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "P_x1 P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "P_x2 P_x1 P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "P_n7";
export var iconSmall = "P_x3";
export var multipleWrapper = "P_x4 d_bC d_bF d_bf";
export var multipleImage = "P_x5 d_bC";
export var sidesPrevContainer = "P_x6 P_xZ P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "P_x7 P_xZ P_xY d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";