// extracted by mini-css-extract-plugin
export var articleWrapper = "k_ny";
export var articleText = "k_nz d_dv d_cs";
export var header = "k_nB d_Z d_w";
export var headerImageWrapper = "k_nC d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "k_nD d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "k_nF d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "k_nG d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "k_nH d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "k_mS d_w";
export var dateTag = "k_nJ d_cf d_cr d_dq d_w";
export var icon1 = "k_nK d_cP";
export var icon2 = "k_nL d_cP d_cJ";
export var tagBtn = "k_nM d_b1 d_cD d_dp d_dB";
export var headerText = "k_nN d_w d_cf d_cs d_bW";
export var center = "k_nP d_dw";
export var videoIframeStyle = "k_nQ d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "k_nR d_cs d_Z";
export var articleImageWrapperIcon = "k_nS d_cs d_Z";
export var articleRow = "k_nT d_bD";
export var quoteWrapper = "k_gQ d_bC d_bP d_cs";
export var quoteBar = "k_nV d_H";
export var quoteText = "k_nW";
export var authorBox = "k_nX d_w";
export var authorRow = "k_nY d_bD d_bW d_cy";
export var separator = "k_nZ d_w";
export var line = "k_fk d_w d_fk d_cY d_cy";
export var authorImage = "k_ln d_b7 d_Z d_w d_ct";
export var authorText = "k_n0 d_ch d_ct";
export var masonryImageWrapper = "k_n1";
export var bottomSeparator = "k_n2 d_w d_cy";
export var linksWrapper = "k_n3 d_ch d_dv";
export var comments = "k_n4 d_bD d_cy";
export var sharing = "k_n5 d_bz d_bD d_bJ";
export var shareText = "k_n6 d_w d_dw";
export var icon = "k_n7";
export var customRow = "k_n8 d_bD d_Z";
export var text = "k_n9";
export var SubtitleSmall = "k_pb";
export var SubtitleNormal = "k_pc";
export var SubtitleLarge = "k_pd";